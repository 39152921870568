//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'

export default {
  methods: {
    ...mapMutations(['setLocationSelector'])
  },
  computed: {
    ...mapState(['location', 'activeOrder']),
    handoffText() {
      if(!this.activeOrder) return ''
      switch (this.activeOrder.handoff) {
        case 'pickup':
          return `<strong class="font-bold">Pickup</strong> AT `
        case 'curbside':
          return `<strong class="font-bold mr-1">Curbside</strong> AT `
        default:
          return `<strong class="font-bold">Delivery</strong> TO `
      }
    },
    isTurkeyLakeRoute() {
      return this.$route.path.includes('/turkey-lake/')
    }
  }
}
